import React from "react"
import Layout from "../components/layout"
import Head from "../components/Head"
import Nav from "../components/Nav"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Form from "../components/Form"

export default () => (
    <Layout>
        <Head />
        <Nav />
        <Header title="Contact me"/>
        <Form />
        <Footer />
    </Layout>
)