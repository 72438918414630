import React from "react"

let Form = () => {

return(
    <div className="form-style-6">
        <form name="main-contact-form" method="post" data-netlify="true">
            <input type="hidden" name="form-name" value="main-contact-form" />        
            <input type="text" name="name" placeholder="Your Name" />
            <input type="email" name="email" placeholder="Email Address" />
            <textarea name="message" placeholder="Type your Message" />
            <input type="submit" name="submit" value="Send" />
        </form>
    </div>
    )
}

export default Form